import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Container, Layout, Button } from '../components';
import * as api from '../utils/api';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import { showNotification, SEVERITY, hideAllNotifications } from '../state/notifications';
import getLanguage from '../utils/getLanguage';
import { Label, Input } from 'theme-ui';
import * as analytics from '../utils/analytics';

const ValidationState = {
  INITIAL: 'INITIAL',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const EmailValidatePage = props => {
  analytics.usePageCategory('omat_sivut');
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const queryParams = queryString.parse(props.location.search);
  const { user, validationId, email } = queryParams;
  const [username, setUsername] = useState(email || '');
  const [failed, setFailed] = useState(false);
  const language = useSelector(state => getLanguage(state));
  const fromEmailLink = !!user && !!validationId;
  const [validationState, setValidationState] = useState(ValidationState.INITIAL);

  useEffect(() => {
    const runEffect = async () => {
      if (user && validationId && !failed) {
        dispatch(hideAllNotifications());
        try {
          const response = await api.verifyEmail(user, validationId);
          setValidationState(ValidationState.SUCCESS);
          dispatch(showNotification('register.message.emailValidationSuccess', SEVERITY.INFO));
          setTimeout(() => {
            navigate('/');
          }, 3000);
        } catch (error) {
          setFailed(true);
          setValidationState(ValidationState.FAILED);
          console.error('Email verification failed: ', error);
          dispatch(showNotification('register.message.emailValidationFailed'));
        }
      }
    };
    runEffect();
  }, [username, user, validationId, failed, dispatch]);

  const resend = async (username, language) => {
    dispatch(hideAllNotifications());
    console.log(username);
    if (!username || username.length < 1) {
      return;
    }
    try {
      const response = await api.resendValidationEmail(username, language);
      console.log(response);
      setUsername('');
      dispatch(showNotification('register.message.resendValidationEmailSuccess', SEVERITY.INFO));
      /**setTimeout(() => {
        navigate('/');
      }, 3000);*/
    } catch (err) {
      console.log('Resending validation email failed', err);
      dispatch(showNotification('register.message.resendValidationEmailFailed'));
    }
  };

  return (
    <Layout paths={props.pageContext.paths} locale={props.pageContext.locale || 'en'}>
      {fromEmailLink ? (
        <Container sx={{ maxWidth: 1024, py: [3, 4] }}>
          <p style={{ color: 'black' }}>
            {validationState === ValidationState.INITIAL
              ? translate('register.message.checkingValidation')
              : validationState === ValidationState.SUCCESS
              ? translate('register.message.emailValidationSuccess')
              : translate('register.message.emailValidationFailed')}
          </p>
        </Container>
      ) : (
        <Container sx={{ maxWidth: 1024, py: [3, 4] }}>
          <p style={{ color: 'black' }}>{translate('register.resendText')}</p>
          <Label>{translate('register.form.email')}</Label>
          <Input value={username} onChange={e => setUsername(e.target.value)} />
          <Button
            onClick={e => {
              e.preventDefault();
              resend(username, language);
            }}
            sx={{ mt: 3 }}
          >
            {translate('register.resendButton')}
          </Button>
        </Container>
      )}
    </Layout>
  );
};

export default EmailValidatePage;
